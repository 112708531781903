import * as React from 'react'
import * as Sentry from '@sentry/nextjs'
import Image from 'next/image'
import { LayoutMain, PageHeader } from '../components'
import { buildStaticProps } from '../ssg/notFound'
import { CompareCredit } from '../../types/compare-credit'
import { nextImageHelper } from '../utils'

function Custom404(props: { notFound: CompareCredit.NotFound.Props }) {
  const { notFound } = props
  const { headline, image, linkPanels, subtitle } = notFound

  // Send a special page view and notify Sentry on 404s
  React.useEffect(() => {
    const { pathname } = window.location
    const error = new PageNotFoundError(pathname)
    Sentry.captureException(error, {
      level: 'warning',
      fingerprint: ['PageNotFoundError', pathname],
      contexts: {
        pageInfo: {
          pathname,
        },
      },
    })

    // doing this last just in-case analytics is not yet initialized
    analytics?.page('404')
  }, [])

  return (
    <LayoutMain>
      <div className="container-fluid / pt-6 pb-20 px-0 sm:pb-24 md:pt-10 md:pb-32">
        <div className="text-center / mx-auto">
          <div className="inline-block / w-1/2 max-w-sm">
            <Image
              height={312}
              width={479}
              alt="404 image"
              src={image.url}
              style={nextImageHelper.responsive}
              sizes="100vw"
            />
          </div>
        </div>

        <PageHeader
          title={headline}
          description={subtitle}
          mWidth="max-w-2xl"
        />

        <div className="max-w-sm md:max-w-5xl mx-auto / flex flex-wrap md:flex-nowrap md:items-stretch md:justify-center / xs:text-xl md:text-sm leading-snug">
          {linkPanels.map((panel: CompareCredit.NotFound.LinkPanel) => (
            <a
              key={panel.linkUrl}
              className="w-full / flex items-center / md:flex-col md:items-start md:flex-wrap md:justify-start / px-2 py-1 md:py-4 md:px-4 / mb-4 md:mb-0 md:mx-1.5 / rounded shadow / text-primary lg:text-base / hover:shadow-lg hover:text-primary-bright / transition-all"
              href={panel.linkUrl}
            >
              <span className="inline-block / sm:w-1/4 md:w-full / md:mb-1 / px-2 py-1 md:px-0 md:py-0 / text-center">
                <span className="inline-block w-12 md:w-auto md:w-14">
                  <Image
                    alt={panel.title}
                    src={panel.icon.url}
                    height={96}
                    width={96}
                    style={nextImageHelper.intrinsic}
                    loading="lazy"
                  />
                </span>
              </span>
              <span className="inline-block / pl-2 / font-bold md:w-full md:text-center">
                {panel.title}
              </span>
            </a>
          ))}
        </div>
      </div>
      <style jsx>{`
        @media (min-width: 640px) {
          .w-30per--sm {
            width: 30%;
          }
        }
      `}</style>
    </LayoutMain>
  )
}

export async function getStaticProps(): Promise<{
  props: {
    notFound: CompareCredit.NotFound.Props
  }
}> {
  const { notFound } = await buildStaticProps()
  return { props: { notFound } }
}

class PageNotFoundError extends Error {
  data: {
    pathname: string
  }

  constructor(pathname: string) {
    super('PageNotFound')
    this.name = `PageNotFoundError: ${pathname}`
    this.data = {
      pathname,
    }
  }
}

export default Custom404
